@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "space-mono_it";
  src: url("../public/font/Space_Mono/SpaceMono-Italic.ttf");
}

@font-face {
  font-family: "space-mono";
  src: url("../public/font/Space_Mono/SpaceMono-Regular.ttf");
}
